import Activity, { ActivityTypeEnum } from './Activity';
import ActivityElement from './ActivityElement';

export enum GenerateDifficultyEnum {
  BEGINNER = 'BEGINNER',
  INTERMEDIATE = 'INTERMEDIATE',
  ADVANCED = 'ADVANCED',
}
export type ActivityGenerate = {
  lessonId: number;
  activityType: Extract<
    ActivityTypeEnum,
    ActivityTypeEnum.TRUE_OR_FALSE | ActivityTypeEnum.QUIZ
  >;
  topic: string;
  difficulty: GenerateDifficultyEnum;
  numQuestions: number;
  additionalInfo: string;
};

export type QuestionGenerated = Omit<
  ActivityElement,
  'identifier' | 'id' | 'order'
>;

export type ActivityGenerated = Omit<Activity, 'id' | 'questions'> & {
  questions: QuestionGenerated[];
};
