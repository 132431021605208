import loadingJson from '@/assets/video/lottie/loading-rocket.json';
import Lottie from 'react-lottie';
import { twMerge } from 'tailwind-merge';

type LoadingViewProps = {
  className?: string;
  classNameContent?: string;
};

export default function LoadingView({
  className,
  classNameContent,
}: LoadingViewProps) {
  return (
    <div
      className={twMerge(
        'flex justify-center items-center h-screen',
        classNameContent,
      )}
    >
      <Lottie
        options={{
          rendererSettings: {
            className: twMerge('w-64 mx-auto', className),
          },
          animationData: loadingJson,
          autoplay: true,
          loop: true,
        }}
      />
    </div>
  );
}
