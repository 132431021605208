import { isArray } from 'lodash';
import { useCallback } from 'react';
import { URLSearchParamsInit, useSearchParams } from 'react-router-dom';

interface UseFilterParamsProps {
  filter?: URLSearchParamsInit;
}

interface ParamsFunctions<T> {
  filterParams: URLSearchParams;
  setFilterParams: <K = T>(params: Partial<K>) => void;
  deleteFilterParams: <T>(keys: keyof T | (keyof T)[]) => void;
  deleteFilterValue: <T>(key: keyof T, value?: string) => void;
}

export default function useFilterParams<K>({
  filter,
}: UseFilterParamsProps = {}): ParamsFunctions<K> {
  const [filterParams, setSearchParams] = useSearchParams(filter);

  const setFilterParams = useCallback(
    function <T = K>(params: Partial<T>) {
      setSearchParams(prev => {
        for (const key in params) {
          const value = params[key];
          prev.delete(key);
          if (!value) continue;
          if (isArray(value))
            value.forEach(value => prev.append(key, value as string));
          else prev.append(key, value as string);
        }
        return prev;
      });
    },
    [setSearchParams],
  );

  const deleteFilterParams = useCallback(
    function <T = K>(keys: keyof T | (keyof T)[]) {
      if (isArray(keys)) {
        setSearchParams(prev => {
          keys.map(key => prev.delete(key as string));
          return prev;
        });
      } else {
        setSearchParams(prev => {
          prev.delete(keys as string);
          return prev;
        });
      }
    },
    [setSearchParams],
  );

  const deleteFilterValue = useCallback(
    function <T = K>(key: keyof T, value?: string) {
      setSearchParams(prev => {
        const values = prev.getAll(key as string);
        if (values && value) {
          const filteredValues = values.filter(
            filterValue => value !== filterValue,
          );
          prev.delete(key as string);
          filteredValues.forEach(filteredValue =>
            prev.append(key as string, filteredValue),
          );
        }
        return prev;
      });
    },
    [setSearchParams],
  );

  return {
    filterParams,
    setFilterParams,
    deleteFilterParams,
    deleteFilterValue,
  };
}
